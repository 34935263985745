/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {ROLE} from '@/constants/role'
import {fetchClientFactory} from '@motiondesign/npm-common-utils/dataclient/fetch'
import {setLoginSuccessRedirect, setUserAuthenticated} from '@/auth/authContext'
import {differenceInDays, differenceInHours, differenceInMinutes} from 'date-fns'
import {SortingState} from '@tanstack/react-table'
import {sortingStateToObject} from '@/components/DataTable'

export function prependPath(object: any, path?: string) {
    Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'object' && 'path' in object[key]) {
            let prependedPath = (path ?? '') + '/' + object[key].path
            object[key].path = prependedPath

            prependPath(object[key], prependedPath)
        }
    })
}

export interface Paginated<T> {
    page: {
        pageNumber: number
        elementsOnPage: number
        totalElements: number
        totalPages: number
    }
    data: T[]
}

/**
 * Returns the number of days since the given date.
 * Rounds up.
 */
export function getDaysSince(someDate: Date): number {
    const currentDate = new Date()
    const differenceInTime = currentDate.getTime() - someDate.getTime()
    return Math.ceil(differenceInTime / (1000 * 3600 * 24))
}

/**
 * Returns the number of days or hours or mins since the given date in text form.
 * Example: 3 day(s) ago, 5 hr(s) ago, 45 min(s) ago
 */
export function getDaysOrTimeSinceMsg(someDate: Date): string {
    // Current date and time
    const now = new Date()

    // Calculate the total difference in days, hours, and minutes
    const diffDays = differenceInDays(now, someDate)
    const diffHours = differenceInHours(now, someDate)
    const diffMinutes = differenceInMinutes(now, someDate)

    // Determine the largest unit of time to display
    if (diffDays > 0) {
        return `${diffDays} day(s) ago`
    } else if (diffHours > 0) {
        return `${diffHours} hour(s) ago`
    } else {
        return `${diffMinutes} minute(s) ago`
    }
}

const {getOrGoLogin, postOrGoLogin, putOrGoLogin, deleteOrGoLogin} = fetchClientFactory(
    () => {
        setUserAuthenticated(false)
        if (!window.location.pathname.includes('login')) {
            setLoginSuccessRedirect(window.location.pathname)
            window.location.reload()
        }
    },
    {autoParseDates: true},
)

export const LAST_SEEN_PROBLEM_DAYS = 5

const API_SCHEMA = (version: string) => ({
    path: '',
    API: {
        path: 'api',
        APPLICATIONS: {
            path: 'applications',
            APPLICATION: {
                path: ':applicationId',
                UPLOAD_VERSION: {
                    path: 'upload-version',
                    getPath(applicationId: number) {
                        return this.path.replace(':applicationId', applicationId.toString())
                    },
                },
            },
        },
    },
    APP: {
        path: 'app',
        LOGIN: {
            path: 'login',
        },
        LOGOUT: {
            path: 'logout',
            goLogout: function () {
                return postOrGoLogin(this, {})
            },
        },
        USERS: {
            path: 'users',
            list: function (): Promise<UserInfo[]> {
                return getOrGoLogin(this)
            },
            save: function (users: UserInfo[]): Promise<UserInfo[]> {
                return postOrGoLogin(this, users)
            },
        },
        USER: {
            path: 'user',
            PROFILE: {
                path: 'profile',
                get: function (): Promise<UserInfo> {
                    return getOrGoLogin(this)
                },
                AVATAR: {
                    path: 'avatar',
                    get(email: string) {
                        return `${this.path}?email=${email}`
                    },
                },
            },
        },
        CUSTOMERS: {
            path: 'customers',
            list: function (): Promise<NamedModel[]> {
                return getOrGoLogin(this)
            },
            save: function (customer: NamedModel[]): Promise<NamedModel[]> {
                return postOrGoLogin(this, customer)
            },
        },
        TYPES: {
            path: 'types',
            CLIENTS: {
                path: 'clients',
                list: function (): Promise<NamedModel[]> {
                    return getOrGoLogin(this)
                },
            },
            APPLICATIONS: {
                path: 'applications',
                list: function (): Promise<ApplicationDto[]> {
                    return getOrGoLogin(this)
                },
                save: function (applications: ApplicationDto[]): Promise<ApplicationDto[]> {
                    return postOrGoLogin(this, applications)
                },
            },
            RELEASE_TYPES: {
                path: 'release-types',
                list: function (applicationId: number | null = null): Promise<ReleaseTypeWithVersion[]> {
                    const options = applicationId ? {applicationId} : {}
                    return getOrGoLogin(this, options)
                },
                save: function (applications: NamedModel[]): Promise<NamedModel[]> {
                    return postOrGoLogin(this, applications)
                },
            },
            APP_RELEASES: {
                path: 'application-release-types',
                list: function (): Promise<AppReleasesDto[]> {
                    return getOrGoLogin(this)
                },
                save: function (applications: AppReleasesDto[]): Promise<AppReleasesDto[]> {
                    return postOrGoLogin(this, applications)
                },
            },
        },
        CLIENTS: {
            path: 'clients',
            list: function (
                page?: number,
                size?: number,
                searchQuery?: string,
                filterByApplication?: string,
                filterByReleaseType?: string,
                filterByCustomer?: string,
                sorting?: SortingState,
            ): Promise<Paginated<ClientDto>> {
                return getOrGoLogin(this, {
                    page,
                    size,
                    searchQuery,
                    filterByApplication,
                    filterByReleaseType,
                    filterByCustomer,
                    ...sortingStateToObject(sorting),
                })
            },

            DETAILS: {
                path: 'details',
                get: function (id: number | string): Promise<ClientDto> {
                    return getOrGoLogin(this, {id})
                },
                save: function (formData: any): Promise<ClientDto> {
                    return postOrGoLogin(this, formData)
                },
                delete: function (id: number): Promise<ClientDto> {
                    return deleteOrGoLogin(this, {id})
                },
            },

            TOKENS: {
                path: 'tokens',
                reset: function (id: number | string): Promise<string> {
                    return getOrGoLogin(this, {id})
                },
            },

            AVAILABLE_VERSION: {
                path: 'available-version',
                get: function (applicationId: number, releaseTypeId: number): Promise<VersionDto> {
                    return getOrGoLogin(this, {applicationId, releaseTypeId})
                },
            },
        },
        APPLICATIONS: {
            path: 'applications',
            list: function (
                page?: number,
                size?: number,
                searchQuery?: string,
                sorting?: SortingState,
            ): Promise<Paginated<AppReleaseVersionsDto>> {
                return getOrGoLogin(this, {
                    page,
                    size,
                    searchQuery,
                    ...sortingStateToObject(sorting),
                })
            },
            save: function (applications: AppReleaseVersionsDto[]): Promise<void> {
                return postOrGoLogin(this, applications)
            },
        },
        SUPPORT: {
            path: 'support',
        },
        CONFIG: {
            path: 'config',

            API_TOKEN: {
                path: 'api-keys',
                list: function (): Promise<ApiKeyDto[]> {
                    return getOrGoLogin(this)
                },
                create: function (name: string): Promise<string> {
                    return putOrGoLogin(this, {name})
                },
                delete: function (id: number) {
                    return deleteOrGoLogin(this, {id})
                },
            },
        },
        NOTIFICATIONS: {
            path: 'notifications',
        },
    },
    init: function () {
        prependPath(this, this.path)
        return this
    },
})

export const DEFAULT_API_VERSION = 'v1'
export const API = API_SCHEMA(DEFAULT_API_VERSION).init()

export const DEFAULT_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss'

export interface UserInfo extends NamedModel {
    email: string
    fullName: string
    active: boolean
    admin: boolean
    roles: ROLE[]
    applicationAccess: ApplicationDto[]
}

export interface Config {
    gRecaptcha: {
        siteKey: string
    }
    sentry: {
        environment: string
        enabled: boolean
    }
}

export type WithNullable<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export interface NamedModel {
    id: number | null
    name: string
}

export interface ApiKeyDto {
    id: number
    name: string
    createdBy: string
    createdDate: Date
    lastUsedTimeStamp: Date | null
}

export interface ReleaseTypeWithVersion {
    id: number
    name: string
    displayVersion: NamedModel | null
}

export interface ApplicationDto {
    id: number | null
    name: string
    fileName: string
    directory: string
}

export interface AppReleasesDto {
    appName: string
    appId: number
    releases: NamedModel[]
}

export interface AppReleaseVersionsDto {
    appName: string
    appId: number
    versions: NamedModel[]
    releaseTypes: ReleaseTypeWithVersion[]
}

export interface ClientDto {
    id: number | null
    name: string
    customer: NamedModel
    releaseType: NamedModel | null
    application: NamedModel
    currentVersion: string
    availableVersion: string
    enabled: boolean
    autoUpdate: boolean
    lastSeen: Date
    internalIpAddress: string | null
    externalIpAddress: string | null
    updateAfter: Date | null
}

export interface VersionDto {
    version: string
    releaseDate: Date | null
}
